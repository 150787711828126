* {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    min-height: 100%;
    height: 100%;
    background-color: #f5f8fa;
}

body {
    min-height: 100%;
    height: 100%;
    width: 100%;
}

#root {
    min-height: 100%;
    height: 100%;
}